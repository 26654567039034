@font-face {
font-family: '__fontClassNames_20ea82';
src: url(/_next/static/media/1df9035b60843e1f-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__fontClassNames_20ea82';
src: url(/_next/static/media/78c250248e5c8529-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__fontClassNames_20ea82';
src: url(/_next/static/media/2adb7836a4d54b09-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__fontClassNames_20ea82';
src: url(/_next/static/media/05f24358f8aea9df-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__fontClassNames_Fallback_20ea82';src: local("Arial");ascent-override: 97.43%;descent-override: 27.65%;line-gap-override: 0.00%;size-adjust: 102.33%
}.__className_20ea82 {font-family: '__fontClassNames_20ea82', '__fontClassNames_Fallback_20ea82', -apple-system,system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif
}

